.dashboard {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dcdcdc;
  padding: 30px;
}

.dashboard__btn {
  padding: 4px;
  font-size: 13px;
  margin-top: 1px;
  margin-left: 980px;
  border: none;
  color: white;
  background-color: black;
  letter-spacing: 1px;
  width: 42%;
}

.dashboard div {
  margin-top: 7px;
}
